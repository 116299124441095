<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{
          $t("{name}'s Calendar", { name: activeDependant.nombre })
        }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <v-date-picker
        v-model="DatepickerDate"
        is-expanded
        :attributes="attributes"
        color="orange"
        :class="
          `calendar-default ${
            store.state.calendar.loadItems ? 'load-items' : ''
          }`
        "
        @dayclick="onDayClick"
        @update:from-page="onUpdateFromPage"
      />
      <skeleton-text-list
        v-if="store.state.calendar.loadItems"
      ></skeleton-text-list>
      <div v-else>
        <template v-if="calendarListItems.length">
          <ion-list class="calendar-items-list" v-if="calendarListItems.length">
            <ion-item
              lines="full"
              v-for="(item, index) in calendarListItems"
              :id="`calendar-item-${item.id}`"
              :key="index"
              :style="`--animation-order: ${index};`"
              class="animate-in-calendar-items-list"
              href="#"
              detail="false"
              @click.prevent="
                router.push(
                  `/calendar-item-detail/${store.getters['calendar/calendarSelectedDayString']}/${item.id}`
                )
              "
            >
              <ion-badge
                slot="start"
                :class="`time ${checkTime(item.fecha_desde)}`"
                >{{
                  `${DateTime.fromSQL(item.fecha_desde).toLocaleString(
                    DateTime.TIME_24_SIMPLE
                  )}h`
                }}</ion-badge
              >
              <ion-label>{{ item.nombre }} {{ item.tipo }}</ion-label>
            </ion-item>
          </ion-list>
        </template>
        <div
          v-else
          class="calendar-no-items-list ion-padding animate-in-calendar-items-list"
        >
          <p>No tienes ningún evento para hoy.</p>
          <div class="img">
            <ion-img src="assets/empty-calendar.svg" class=""></ion-img>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonImg
} from "@ionic/vue";
import { computed, ref, nextTick, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { DateTime } from "@/plugins/luxon";
import SkeletonTextList from "@/components/SkeletonTextList.vue";
import { useRouter } from "vue-router";

export default {
  name: "Calendar",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonBadge,
    IonImg,
    SkeletonTextList
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const activeDependant = computed(() => store.state.auth.activeDependant);
    const listItems = ref([]);
    const calendarListItems = computed(() => {
      if (listItems.value.length == 0) {
        return [];
      }

      return listItems.value.filter(item => item.tipo == "recordatorio");
    });
    const selectedDay = computed(
      () => store.getters["calendar/calendarSelectedDay"]
    );
    const DatepickerDate = ref(new Date());
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const now = DateTime.now();
    const checkTime = date => {
      date = DateTime.fromSQL(date);

      let styleClass = "after-now";
      if (date < now) {
        styleClass = "before-now";
      }
      return styleClass;
    };

    const findDayWithItems = async function(day, $empty_before = true) {
      // Vacío la lista de items
      if ($empty_before) listItems.value = [];
      let dayWithItems = store.state.calendar.items.find(d => d.date === day);
      if (dayWithItems) {
        if ($empty_before) await nextTick();
        listItems.value = dayWithItems.items;
      }
    };

    const onDayClick = function(day) {
      store.dispatch("calendar/changeCalendarSelectedDay", day.id);
    };

    const onUpdateFromPage = async function(page) {
      const { year, month } = page;
      console.log(year, month);
      let day = 1;

      if (year == today.getFullYear() && month == today.getMonth() + 1) {
        day = today.getDate();
      }

      const date = `${year.toString()}-${month
        .toString()
        .padStart(2, 0)}-${day.toString().padStart(2, 0)}`;

      // He hecho este if para que la primera vez que entre y vea que listItems está vacío se espere a que termine de cargar
      // Tengo que hacerlo aquí porque onUpdateFromPage(), que se llama desde el datepiker, la primera vez se llama antes de onBeforeMount
      // TODO: Ver si hay alguna forma mejor de hacer esto
      if (listItems.value.length > 0) {
        store.dispatch("calendar/getItems", { year: year, month: month });
        store.dispatch("calendar/changeCalendarSelectedDay", date);
      } else {
        await store.dispatch("calendar/getItems", { year: year, month: month });
        await store.dispatch("calendar/changeCalendarSelectedDay", date);
      }

      DatepickerDate.value = new Date(year, month - 1, day);
    };

    watch(selectedDay, (currentValue, oldValue) => {
      if (currentValue !== oldValue) {
        findDayWithItems(currentValue.toFormat("yyyy-MM-dd"));
      }
    });

    const attributes = computed(() => {
      return [
        // Attributes for items
        ...store.state.calendar.items
          .filter(
            day =>
              day.items.filter(item => item.tipo == "recordatorio").length > 0
          )
          .map(day => ({
            dates: new Date(day.date),
            dot: true
            // customData: day.items
          })),
        {
          key: "before-today",
          highlight: {
            contentClass: "before-today"
          },
          dates: [
            {
              end: yesterday
            }
          ]
        }
      ];
    });

    onBeforeMount(async () => {
      store.dispatch("calendar/setLoadItems", true);
    });

    return {
      DatepickerDate,
      activeDependant,
      attributes,
      listItems,
      checkTime,
      onDayClick,
      DateTime,
      onUpdateFromPage,
      store,
      calendarListItems,
      router
    };
  }
};
</script>

<style lang="scss" scoped></style>
