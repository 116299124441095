<template>
  <ion-list class="calendar-skeleton-text-list">
    <ion-item>
      <ion-skeleton-text
        animated
        style="width: 47.52px; height: 21px"
        slot="start"
      ></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <ion-skeleton-text
        animated
        style="width: 47.52px; height: 21px"
        slot="start"
      ></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <ion-skeleton-text
        animated
        style="width: 47.52px; height: 21px"
        slot="start"
      ></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-item>
  </ion-list>
</template>

<script>
import { IonSkeletonText, IonList, IonItem } from "@ionic/vue";

export default {
  name: "SkeletonTextList",
  components: { IonSkeletonText, IonList, IonItem },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";
</style>
